import { graphql } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import React, { ReactElement, ReactNode } from "react";
import styled from "styled-components";
import { BLOCKS, Block, Inline } from "@contentful/rich-text-types";

import { GatsbyImage, Section } from "@Components/blocks";
import { ScreenSize, Size } from "@Components/core";
import { Footer, Page, Navigation } from "@Components/layout";
import { useImageQuery } from "@Hooks/image";
import { useNavigationQuery } from "@Hooks/navigation";
import { useSectionQuery, useSectionTypeParser } from "@Hooks/section";

const BlogPost = styled(Section)`
  margin: 0 auto;
  max-width: ${ScreenSize.lg};
`;

const BlogPostImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-block-end: ${Size.blog.image.margin.bottom};
  margin-block-start: ${Size.blog.image.margin.top};
  margin-inline-end: ${Size.blog.image.margin.right};
  margin-inline-start: ${Size.blog.image.margin.left};
`;

const BlogPostTemplate = ({ data }: any) => {
  const blogPost = data.contentfulBlog;
  const navigationQuery = useNavigationQuery();
  const sectionQuery = useSectionQuery("blog");
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node: Block | Inline, children: ReactNode) => {
        // Contentful has this issue of not being able to distinguish between
        // inline code and block code, which results in formatting issues.
        // This attempts to find all the code blocks and separate them by
        // code block and inline code to format differently.
        let hasBlockCode = false;

        React.Children.forEach(children, (child: ReactNode) => {
          const elementChild = child as ReactElement;
          hasBlockCode =
            elementChild.type === "code" &&
            elementChild.props.children.toLocaleString().includes("\n");
        });

        const formattedChildren = <p>{children}</p>;
        if (hasBlockCode) {
          return <pre>{formattedChildren}</pre>;
        }
        return formattedChildren;
      },
      [BLOCKS.EMBEDDED_ASSET]: (node: Block | Inline) => {
        const imageQuery = useImageQuery(node.data.target["contentful_id"]);
        return (
          <BlogPostImageWrapper>
            <GatsbyImage
              alt={imageQuery?.description ?? ""}
              image={node.data.target.gatsbyImageData}
            />
          </BlogPostImageWrapper>
        );
      },
    },
  };

  return (
    <Page>
      <Navigation {...navigationQuery} />
      <BlogPost align="left" background="white">
        {renderRichText(blogPost.content, options)}
      </BlogPost>
      {sectionQuery?.map((section, index) => {
        const SectionComponent = useSectionTypeParser(section.layout);
        return SectionComponent ? (
          <div id={section.name} key={index}>
            <SectionComponent {...section.body} />
          </div>
        ) : undefined;
      })}
      <Footer background="blue" />
    </Page>
  );
};

export default BlogPostTemplate;

export const query = graphql`
  query BlogPostQuery($slug: String!) {
    contentfulBlog(slug: { eq: $slug }) {
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData
          }
        }
      }
      date
      thumbnail {
        description
        gatsbyImageData
      }
      title
    }
  }
`;
